<template>
  <v-menu v-if="hasItems" v-model="opened" offset-y>
    <template #activator="{ on }">
      <span>
        <v-btn
          class="ml-4 quick-add-button opacity-blended"
          small
          outlined
          v-on="on"
        >
          <v-icon color="accent">add</v-icon>
          <span color="accent" v-text="$t('common.create.btn')" />
        </v-btn>
      </span>
    </template>
    <AppActionMenu divider :menu="menu" />
  </v-menu>
</template>
<script lang="ts">
import Vue from 'vue'
import { MENU_QUICK_ADD_EXTENSION } from './index'
import { modularManager } from '@/tt-app-modular'
import { ActionMenuItem, ActionMenuConfig } from '@/tt-app-layout'

/**
 * Quick add extension
 */
export default Vue.extend({
  name: 'MenuQuickAddExtension',
  data() {
    return {
      opened: false,
    }
  },
  computed: {
    hasItems(): boolean {
      return modularManager.hasItems(MENU_QUICK_ADD_EXTENSION)
    },
    items(): ActionMenuItem[] | null {
      return modularManager.getItems(MENU_QUICK_ADD_EXTENSION) as
        | ActionMenuItem[]
        | null
    },
    menu(): ActionMenuConfig | null {
      return { items: this.items }
    },
  },
  methods: {
    dispatch(eventItem: any) {
      this.$eventManager.dispatchEvent(eventItem.event, eventItem.payload)
      this.opened = false
    },
  },
})
</script>
<style>
.quick-add-button {
  margin-top: 10px;
  border-color: #ccc;
}
</style>
